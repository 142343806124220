export const environment = {
  production: true,
  APPUrl: "https://kml.kpmg.com/",
  APIUrl: "https://kmlapi.kpmg.com/api/",
  ClientID: "e1818b3f-3b1c-4f18-a15b-8d5428999643",
  instrumentationKey: "",
  AuthorityURL: "https://login.microsoftonline.com/deff24bb-2089-4400-8c8e-f71e680378b2",
  authenticationMessage: "Auth Token expired",
  sessionTimoutMessage: "Session Timed out - Please login",
  idle_timer: 1200,
  refreshtoken_timer: 2000000,
  gridLoadValue: 100,
  handsontableLicenseKey: "3bb31-c3a50-e1115-84e35-43e2a"
};
